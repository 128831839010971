import React from 'react';
import Card from '../components/Card';
import Layout from './Layout';
import useDeviceType from '../utils/useDeviceType';
import { infrastructureData as Infrastructure } from '../json/Infrastructure';
import Breadcrumb from '../components/Breadcrumb';

const OurInfraStructure = () => {
  const deviceType = useDeviceType();

  const containerStyle = {
    height: 'auto',
    width: '100%',
    color: 'black',
    padding: deviceType === 'mobile' ? '10px' : '50px',
  };

  return (
    <Layout>
      <Breadcrumb path={['Home', 'Our Infrastructure']} />
      <div style={containerStyle}>
        <section 
          style={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: '40px' 
          }}
        >
          {Infrastructure?.map((infra) => (
            <Card 
              key={infra?.id || infra?.name}
              image={infra?.image} 
              name={infra?.name} 
              description={infra?.description} 
            />
          ))}
        </section>
      </div>
    </Layout>
  );
};

export default OurInfraStructure;
