import React from 'react';
import useDeviceType from '../utils/useDeviceType';

const Breadcrumb = ({ path = [], backgroundImage }) => {
  const deviceType = useDeviceType();
  const isMobile = deviceType === 'mobile';

  if (!Array.isArray(path)) {
    console.error('Breadcrumb path should be an array');
    return null;
  }

  const pageName = path.length > 0 ? path[path.length - 1] : '';

  return (
    <nav style={{ 
        margin: '20px 0',
        height: isMobile ? '60px' : '100px', 
        backgroundColor: '#faebe8', 
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: isMobile ? '0px' : '10px'
      }}
    >
      <ul style={{ listStyle: 'none', display: 'flex', padding: 0, margin: 0 }}>
        <li style={{ display: 'flex', alignItems: 'center', color: '#a11a02', fontSize: isMobile ? '30px' : '40px', fontWeight: '900' }}>
          <span>{pageName}</span>
        </li>
      </ul>
    </nav>
  );
};

export default Breadcrumb;
