import React from 'react';

const Input = ({ label, type, id, name, value, onChange, placeholder, error }) => {
  return (
    <div style={{ marginBottom: '20px', width: '100%' }}>
      <label
        htmlFor={id}
        style={{ display: 'block', marginBottom: '5px', fontWeight: 600, color: '#333' }}
      >
        {label}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        style={{
          width: '100%',
          height: '45px',
          padding: '15px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
        }}
        onFocus={(e) => {
          e.target.style.borderColor = '#007BFF';
          e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
        }}
        onBlur={(e) => {
          e.target.style.borderColor = '#ccc';
          e.target.style.boxShadow = 'none';
        }}
      />
      {error && <p style={{ color: 'red', fontSize: '18px', marginTop: '5px' }}>{error}</p>}
    </div>
  );
};

export default Input;
