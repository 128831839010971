import React, { useEffect, useState, useRef } from 'react';
import Layout from './Layout';
import OurTeamGif from '../assets/videos/ourTeamGif.mp4';
import ourTeamMobileGif from '../assets/videos/ourTeamMobileGif.mp4';
import useDeviceType from '../utils/useDeviceType';
import Team from '../components/Team'; 
import rajuImage from '../assets/images/team/raju.jpg';
import rahulImage from '../assets/images/team/rahul.jpg';
import simranImage from '../assets/images/team/simran.jpg';

const OurTeam = () => {
  const deviceType = useDeviceType();
  const ourTeamVideoRef = useRef(null);
  const clickableAreaHeight = deviceType === 'mobile' ? '14%' : '20%';
  const clickableAreaWidth = deviceType === 'mobile' ? '25%' : '9%';
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalPhotos] = useState([
    { src: rahulImage },
    { src: rajuImage }, 
    { src: simranImage }, 
  ]);
  const designations = ['Operations Head', 'Founder', 'Marketing Head']; 
  const names = ['Mr Rahul Yadav', 'Mr Raju Yadav', 'Ms Simran Anchan']; 

  const handleVideoPlay = () => {
    sessionStorage.setItem('ourTeamVideoRef', 'true');
    setIsVideoPlayed(true);
  };

  useEffect(() => {
    const hasPlayed = sessionStorage.getItem('ourTeamVideoRef');
    const video = ourTeamVideoRef.current;

    if (hasPlayed && video) {
      setIsVideoPlayed(true);
      video.addEventListener('loadedmetadata', () => {
        if (video.duration) {
          video.currentTime = video.duration;
          video.pause();
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('ourTeamVideoRef');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const openModal = (index) => {
    if (isVideoPlayed) {
      setCurrentImageIndex(index);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <div
        style={
          deviceType === 'mobile'
            ? {
              backgroundColor: 'transparent',
              position: 'relative',
              height: '100%',
              width: '100%',
              padding: '20px',
              marginBottom: '60px',
            }
            : {
              backgroundColor: 'transparent',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              height: '100%',
              width: '100%',
            }
        }
      >
        <video
          ref={ourTeamVideoRef}
          src={deviceType === 'mobile' ? ourTeamMobileGif : OurTeamGif}
          type='video/mp4'
          autoPlay
          muted
          onPlay={handleVideoPlay}
          controls={false}
          style={
            deviceType === 'mobile' ?
            {
                width: '100%',
            } :
            {
                width: '71%'
            }
          }
        ></video>

        <div 
          onClick={() => openModal(0)}
          style={{
            position: 'absolute',
            top: deviceType === 'mobile' ? '44%' : '15%',
            left: deviceType === 'mobile' ? '14%' : '32%',
            width: clickableAreaWidth,
            height: clickableAreaHeight,
            cursor: isVideoPlayed ? 'pointer' : 'default',
            pointerEvents: isVideoPlayed ? 'auto' : 'none',
            borderRadius: '50%'
          }}
        ></div>

        <div 
          onClick={() => openModal(1)} 
          style={{
            position: 'absolute',
            top: deviceType === 'mobile' ? '9%' : '3%',
            left: deviceType === 'mobile' ? '39%' : '46%',
            width: clickableAreaWidth,
            height: clickableAreaHeight,
            cursor: isVideoPlayed ? 'pointer' : 'default',
            pointerEvents: isVideoPlayed ? 'auto' : 'none',
            borderRadius: '50%'
          }}
        ></div>

        <div 
          onClick={() => openModal(2)} 
          style={{
            position: 'absolute',
            top: deviceType === 'mobile' ? '44%' : '11%',
            right: deviceType === 'mobile' ? '18%' : '32%',
            width: clickableAreaWidth,
            height: clickableAreaHeight,
            cursor: isVideoPlayed ? 'pointer' : 'default',
            pointerEvents: isVideoPlayed ? 'auto' : 'none',
            borderRadius: '50%'
          }}
        ></div>

        <Team 
          isOpen={modalOpen}
          onClose={closeModal}
          designation={designations[currentImageIndex]}
          name={names[currentImageIndex]} 
          imageSrc={modalPhotos[currentImageIndex]?.src} 
        />
      </div>
    </Layout>
  );
};

export default OurTeam;
