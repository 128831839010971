import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import ServiceDetails from './pages/ServiceDetails';
import OurNetwork from './pages/OurNetwork';
import OurTeam from './pages/OurTeam';
import OurInfraStructure from './pages/OurInfraStructure';
import ContactUs from './pages/ContactUs';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />}  />
      <Route path="/services/" element={<Services />} />
      {/* <Route path="/services/:categoryName" element={<ServiceDetails />} /> */}
      <Route path="/service-details" element={<ServiceDetails />} />
      <Route path="/our-network" element={<OurNetwork />} />
      <Route path="/our-team" element={<OurTeam />} />
      <Route path="/our-infrastructure" element={<OurInfraStructure />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="*" element={<h1>404 Not Found</h1>} />
    </Routes>
  );
};

export default App;
