import React from 'react';

const TickerTape = ({ tickerText }) => {
  return (
    <div className="ticker-container">
      <div className="ticker-text">
        <strong>Get in Touch</strong>{tickerText}
      </div>
    </div>
  );
};

export default TickerTape;
