import React from 'react';
import Layout from './Layout';
import aboutImage from '../assets/images/about-us.jpg';
import clients from '../assets/images/desktop-clients1.png';
import clientsMobile from '../assets/images/mobile-clients.jpeg';
import useDeviceType from '../utils/useDeviceType';
import Breadcrumb from '../components/Breadcrumb';

const AboutUs = () => {
  const deviceType = useDeviceType();
  const isMobile = deviceType === 'mobile';

  const sectionPadding = isMobile ? '20px' : '50px';
  const contentPadding = isMobile ? '15px 10px' : '20px';
  const textStyle = { marginBottom: isMobile ? '5px' : '0px' };

  return (
    <Layout>
      <Breadcrumb path={['Home', 'About Us']} />

      <div
        style={{
          width: '100%',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
        }}
      >
        <section
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            marginBottom: '50px',
            padding: sectionPadding,
            gap: '30px',
          }}
        >
          <div style={{ width: isMobile ? '100%' : '50%', padding: isMobile ? '0px' : '20px' }}>
            <img
              src={aboutImage}
              alt="About Us"
              style={{
                width: '100%',
                height: isMobile ? 'auto' : '600px',
                objectFit: 'cover',
                border: '1px solid #fad6cf',
                borderRadius: '10px',
              }}
            />
          </div>

          <div
            style={{
              padding: contentPadding,
              backgroundColor: '#fcf8f7',
              borderRadius: '10px',
              border: '1px solid #f0f0f0',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
              width: isMobile ? '100%' : '50%',
              minHeight: isMobile ? 'auto' : '600px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              justifyContent: 'space-between',
            }}
          >
            <h3 style={textStyle}>Our Mission</h3>
            <p style={{ ...textStyle, paddingLeft: '10px' }}>
              At Elora Art, we strive relentlessly to establish ourselves as the world’s most respected provider of marketing promotion services. Each day, we push the boundaries of creativity and excellence to exceed the expectations of our clients and industry peers alike.
            </p>

            <h3 style={textStyle}>Our Vision</h3>
            <p style={{ ...textStyle, paddingLeft: '10px' }}>
              To become the most passionately referred agency in the Advertising industry, setting new benchmarks through our unwavering dedication to quality, reliability, and client success.
            </p>

            <h3 style={textStyle}>What Sets Us Apart</h3>
            <ul style={{ paddingLeft: '30px', listStyleType: 'disc' }}>
              <li style={textStyle}>Creative Excellence: Our team is renowned for transforming innovative ideas into impactful campaigns that resonate with audiences.</li>
              <li style={textStyle}>Cutting-Edge Technology: Leveraging state-of-the-art large format digital printers to ensure precision and quality in every project.</li>
              <li style={textStyle}>Strong Market Presence: With a robust network and expertise, we deliver insightful solutions at competitive rates, tailored to meet diverse client needs.</li>
            </ul>

            <h3 style={textStyle}>Why Choose Elora Art?</h3>
            <ul style={{ paddingLeft: '30px', listStyleType: 'disc' }}>
              <li style={textStyle}>Quality Assurance: We prioritize delivering superior products and services that meet the highest industry standards.</li>
              <li style={textStyle}>Timely Delivery: Committed to prompt execution of projects without compromising on quality.</li>
              <li style={textStyle}>Customer-Centric Approach: Your success is our success; we are dedicated to building lasting partnerships based on trust and results.</li>
            </ul>

            <h3 style={textStyle}>Get in Touch</h3>
            <p style={{ ...textStyle, paddingLeft: '10px' }}>
              Have questions or ready to discuss your next project? Contact us today for personalized consultation and expert advice.
            </p>
          </div>
        </section>

        <section style={{ marginTop: '50px' }}>
          <h2 style={{ textAlign: 'center' }}>Our Valuable Clients</h2>
          <div style={{ padding: sectionPadding }}>
            <img
              src={isMobile ? clientsMobile : clients}
              alt='Our Clients'
              style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', display: 'block', borderRadius: '10px' }}
            />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutUs;
