import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Layout from './Layout';
import Modal from '../components/Modal';
import useDeviceType from '../utils/useDeviceType';
import comingSoonImage from '../assets/images/coming-soon.jpg';
import axios from 'axios';
import { API_URL, APP_URL } from '../constant';

const ServiceDetails = () => {
  // const { categoryName } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryName = queryParams.get('category');

  const deviceType = useDeviceType();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAllServices = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${API_URL}/get-product-data`, { category_name: categoryName });
      const photos = response.data.data.map(product => `${APP_URL}/storage/${product.photo}`);
      setPhotos(photos);
    } catch (error) {
      console.error('Error fetching services:', error);
      setError('Failed to fetch services. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [categoryName]);

  useEffect(() => {
    fetchAllServices();
  }, [fetchAllServices]);

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentIndex(0);
  };

  return (
    <Layout>
      <div
        style={{
          padding: deviceType === 'mobile' ? '30px' : '40px 0px',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '70vh'
        }}
      >
        {(loading || error || photos?.length === 0) ? (
          <img 
            src={comingSoonImage} 
            alt='Coming Soon' 
            style={{
              height: '60%',
              width: '60%',
              objectFit: 'cover'
            }}
          />
        ) : (
          <div
            style={{
              display: 'grid',
              gap: '20px',
              gridTemplateColumns: deviceType === 'mobile' ? '1fr' : 'repeat(3, 1fr)',
            }}
          >
            {photos?.map((photo, index) => (
              <div 
                key={index} 
                style={{
                  height: '300px',
                  boxSizing: 'border-box',
                  padding: deviceType === 'mobile' ? '20px' : '15px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  overflow: 'hidden',
                  transition: 'transform 0.3s',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                }}
                onClick={() => openModal(index)}
              >
                <img
                  src={photo} 
                  alt={`Service ${index + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'filter 0.3s',
                    borderRadius: '8px',
                  }}
                  onMouseOver={(e) => e.currentTarget.style.filter = 'brightness(0.8)'}
                  onMouseOut={(e) => e.currentTarget.style.filter = 'none'}
                />
              </div>
            ))}
          </div>
        )}

        <Modal 
          isOpen={modalOpen}
          onClose={closeModal}
          photos={photos}
          currentIndex={currentIndex}
        />
      </div>
    </Layout>
  );
}

export default ServiceDetails;
