import React, { useState } from 'react';
import useDeviceType from '../utils/useDeviceType';

const Card = ({ image, name, description }) => {
  const deviceType = useDeviceType();
  const [isHovered, setIsHovered] = useState(false);

  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '10px',
    overflow: 'hidden',
    width: deviceType === 'mobile' ? '100%' : '48%',
    transition: 'transform 0.2s, box-shadow 0.2s',
    cursor: 'pointer',
    boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
    backgroundColor: '#fcf8f7'
  };

  const imageStyle = {
    width: '100%',
    height: '350px',
    objectFit: 'cover',
    padding: '10px',
  };

  const contentStyle = {
    padding: '30px',
    textAlign: 'left'
  };

  const titleStyle = {
    margin: '0 0 10px 0',
    fontSize: '1.25rem',
    fontWeight: 'bold',
  };

  const descriptionStyle = {
    margin: '0',
    color: '#555'
  };

  return (
    <div
      style={cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={image} alt={name} style={imageStyle} />
      <div style={contentStyle}>
        <h3 style={titleStyle}>{name}</h3>
        <p style={descriptionStyle}>{description}</p>
      </div>
    </div>
  );
};

export default Card;
