import React from 'react';
import logo from '../assets/images/logos/footer-logo.png';
import '../App.css';
import useDeviceType from '../utils/useDeviceType';
import { headerMenus } from '../json/headerMenus';
import { useNavigate } from 'react-router-dom';
// import facebookIcon from '../assets/images/icons/Facebook.svg';
// import linkedinIcon from '../assets/images/icons/LinkedIn.svg';
// import instagramIcon from '../assets/images/icons/Instagram.svg';
// import youtubeIcon from '../assets/images/icons/Youtube.svg';

const Footer = () => {
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const isMobile = deviceType === 'mobile';

  return (
    <footer className='footer' style={{boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.05)', backgroundColor: '#fcf8f7'}}>
      <div className={`footer-container ${isMobile ? 'mobile' : 'desktop'}`}>
        <div className='footer-logo-section'>
          <div className='footer-logo' style={{marginTop: '40px'}}>
            <div onClick={() => navigate('/')} aria-label='Logo' style={{ cursor: 'pointer' }}>
              <img alt='Logo' loading='lazy' src={logo} />
            </div>
          </div>

          {/* <div className='footer-social-icons'>
            <a href='https://www.facebook.com/yourpage' target='_blank' rel='noopener noreferrer' aria-label='Facebook'>
              <img src={facebookIcon} alt='Facebook' />
            </a>
            <a href='https://www.linkedin.com/yourpage' target='_blank' rel='noopener noreferrer' aria-label='LinkedIn'>
              <img src={linkedinIcon} alt='LinkedIn' />
            </a>
            <a href='https://www.instagram.com/yourpage' target='_blank' rel='noopener noreferrer' aria-label='Instagram'>
              <img src={instagramIcon} alt='Instagram' />
            </a>
            <a href='https://www.youtube.com/yourpage' target='_blank' rel='noopener noreferrer' aria-label='YouTube'>
              <img src={youtubeIcon} alt='Youtube' />
            </a>
          </div> */}
        </div>

        <div className='footer-columns' style={deviceType === 'mobile' ? { gap: '100px', paddingLeft: '20px', paddingRight: '20px' } : { gap: '250px' }}>
          <div className='footer-column'>
            <p className='footer-column-title'>Quick Links</p>
            {headerMenus?.map((menu) => (
              <p 
                key={menu.path}
                className='footer-link'
                onClick={() => navigate(menu?.path)}
              >
                {menu?.name}
              </p>
            ))}
          </div>

          <div className='footer-column'>
            <p className='footer-column-title'>Contact Us</p>
            <p className='footer-link' style={{marginBottom: '20px'}}>
              <a href='mailto:raju@eloraart.com' className='footer-contact-link'>Email: raju@eloraart.com</a>
            </p>
            <p className='footer-link'>
              <a href='tel:9920153533' className='footer-contact-link'>Phone: +91 99201 53533</a>
            </p>
            <p className='footer-link'>
              <a href='tel:7208341924' className='footer-contact-link'>Phone: +91 72083 41924</a>
            </p>
            <p className='footer-link' style={{marginTop: '20px'}}>
              Address: 306 Shivam Chamber, Near Ram Mandir Signal,<br />
              S.V. Road, Goregoan West, Mumbai 400104,<br />
              Maharashtra, India
            </p>
          </div>
        </div>
      </div>

      <div className='footer-copyright' style={
        deviceType === 'mobile' ? 
        {
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        } : 
        {
          flexDirection: 'row',
          gap: '20px',
        }
      }>
        <p>© 2023 <span onClick={() => navigate('/')} className='footer-copyright-link' style={{ cursor: 'pointer' }}>Elora Art</span>. All Rights Reserved.</p>
        <p>Developed by | <a href='https://technicul.com/' target='_blank' rel='noopener noreferrer' className='footer-copyright-link'>Technicul</a>.</p>
      </div>
    </footer>
  );
};

export default Footer;
